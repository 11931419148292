<template lang="pug">
  .manage-access-page
    .header 
      .nio-jumbo-2.text-white Seller Studio
      .nio-p-large.text-primary-light Create datastreams from your datasets.
</template>

<script>


export default {
  data: () => ({
 
  }),	
  mounted() {
  },
  computed: {
  },
  methods: {
  
  },
  components: { }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.manage-access-page
 
</style>